"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delete_archived_auction = void 0;
var api_1 = require("../../api");
var delete_archived_auction = function (props) {
    return (0, api_1.apiDelete)({
        endpoint: "v3/archive/delete",
        data: props,
    });
};
exports.delete_archived_auction = delete_archived_auction;
