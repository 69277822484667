"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.archived_auctions_for_client = void 0;
var api_1 = require("../../api");
var archived_auctions_for_client = function (props) {
    return (0, api_1.apiGet)({
        endpoint: "v3/archive/auctions_for_client",
        // data: request,
        query: props,
    });
};
exports.archived_auctions_for_client = archived_auctions_for_client;
