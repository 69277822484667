"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restore_auction = void 0;
var api_1 = require("../../api");
var restore_auction = function (props) {
    return (0, api_1.apiPatch)({
        endpoint: "v3/archive/restore",
        data: props,
    });
};
exports.restore_auction = restore_auction;
