"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.archive_auction = void 0;
var api_1 = require("../../api");
var archive_auction = function (props) {
    return (0, api_1.apiPatch)({
        endpoint: "v3/archive/archive",
        data: props,
    });
};
exports.archive_auction = archive_auction;
