"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.message_restore_auction = exports.message_archive_auction = void 0;
exports.message_archive_auction = [
    "Are you sure that you want to archive this auction?",
    "- This will also archive the Lots, Images, Documents and Bids associated with this auction.",
    "- You will be able to restore everything later.",
];
exports.message_restore_auction = [
    "Are you sure that you want to restore this auction?",
    "- This will also restore the Lots, Images, Documents and Bids associated with this auction.",
    "- You will be able to archive everything later again.",
];
