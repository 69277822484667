"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchivedPage = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var functions_1 = require("../../../constants/functions");
var bidder_1 = require("../../../reducers/slices/bidder");
var main_1 = require("../../../reducers/slices/main");
var get_archive_1 = require("../../../api/calls/archive/get_archive");
var messages_1 = require("../../../constants/messages");
var restore_auction_1 = require("../../../api/calls/archive/restore_auction");
var ArchivedPage = function (props) {
    var onSuccess = props.onSuccess;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var _a = (0, react_1.useState)(null), auction = _a[0], setAuction = _a[1];
    var _b = (0, react_1.useState)(""), filterNumber = _b[0], setFilterNumber = _b[1];
    var _c = (0, react_1.useState)(""), csv = _c[0], setCSV = _c[1];
    var _d = (0, react_1.useState)(true), isLoading = _d[0], setIsLoading = _d[1];
    var _e = (0, react_1.useState)(false), isGettingCSV = _e[0], setIsGettingCSV = _e[1];
    (0, react_1.useEffect)(function () {
        if (params._id) {
            setIsLoading(true);
            (0, get_archive_1.get_archive)({ _id: params._id }).then(function (response) {
                setIsLoading(false);
                if (response.data.success) {
                    setAuction(response.data.content);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Oops!",
                        messages: [
                            "Something went wrong while getting the auction report.",
                            response.data.message,
                        ],
                    }));
                }
            });
        }
    }, [params._id]);
    return (react_1.default.createElement("div", { className: "p-4" },
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Archived Auction", textColor: "text-black", size: "3xlarge", bold: true })),
        react_1.default.createElement("div", { className: "flex justify-between gap-4 py-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Restore This Auction", backgroundColor: "bg-orange-600", size: "small", onClick: function () {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Restore?",
                        messages: messages_1.message_restore_auction,
                        buttons: "yesNo",
                        onConfirm: function () {
                            (0, restore_auction_1.restore_auction)({
                                _id: auction._id,
                            }).then(function (response) {
                                if (response.data.success) {
                                    dispatch((0, main_1.setMessageModal)(null));
                                    onSuccess();
                                    navigate("/console/auctions");
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while restoring the auction!", messages: [response.data.message] }));
                                }
                            });
                        },
                    }));
                } })),
        isLoading ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null))) : (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: auction.title, textColor: "text-black", size: "2xlarge", bold: true })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: auction.description, textColor: "text-black", size: "xsmall" })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: auction.status, textColor: "text-black", size: "medium", bold: true })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "flex gap-8" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Start Date: ".concat((0, functions_1.formatDate)(auction.date_from, //
                            "Do MMM YYYY, h:mm A")), textColor: "text-black", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "End Date: ".concat((0, functions_1.formatDate)(auction.date_to, //
                            "Do MMM YYYY, h:mm A")), textColor: "text-black", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Archived At: ".concat((0, functions_1.formatDate)(auction.archived_at, //
                            "Do MMM YYYY, h:mm A")), textColor: "text-black", size: "small" }))),
                react_1.default.createElement("div", { className: "py-8" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Number of Lots: ".concat(auction.number_of_lots), textColor: "text-black", size: "small" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Number of Bids Placed: ".concat(auction.number_of_bids), textColor: "text-black", size: "small" }),
                    react_1.default.createElement("div", { className: "flex gap-1" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Highest Bids Combined:", textColor: "text-black", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, functions_1.formatMoney)(auction.highest_bids_combined), textColor: "text-black", size: "small", bold: true })))),
            react_1.default.createElement("div", { className: "flex gap-4 items-center" },
                react_1.default.createElement("div", { className: "flex-1" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Filter Lot #", type: "number", borderColor: "border-gray-400", value: filterNumber, startIcon: pro_solid_svg_icons_1.faSearch, onChange: function (v) {
                            setFilterNumber(v);
                        } }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" }, auction.lots && auction.lots.length > 0 ? (auction.lots.map(function (lot, lotIdx) {
                return (((lotIdx + 1).toString() == filterNumber || !filterNumber) && (react_1.default.createElement(doshx_controls_web_1.BoxControl, { key: lotIdx, className: "flex flex-col pl-4 py-4 items-start" },
                    react_1.default.createElement("div", { key: lot._id, className: "flex flex-row relative items-center justify-start max-w-lg gap-4" },
                        react_1.default.createElement("div", { className: "".concat(lot.status == "Sold" ? "bg-green-600" : "bg-orange-400", "\n                                    py-1 px-2 rounded-full flex-none") },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: lot.status, textColor: "text-white" })),
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Lot #".concat(lotIdx + 1, " - ").concat(lot.title), textColor: "text-black", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: lot.description, size: "xsmall" }))),
                    react_1.default.createElement("div", { className: "pl-4 py-4 flex flex-col gap-4 max-h-[300px] overflow-y-auto pr-8" }, lot.bids && lot.bids.length > 0
                        ? lot.bids.map(function (bid, bidIdx) {
                            var _a, _b, _c;
                            // highest bid cannot be rejected, so filter out the highest bid, then use its first index
                            var isHighestBid = ((_a = lot.bids.filter(function (b) { return b.status == "Active"; }).sort(function (a, b) { return b.amount - a.amount; })[0]) === null || _a === void 0 ? void 0 : _a._id) == bid._id;
                            return (react_1.default.createElement("div", { key: bid._id, className: "flex flex-row gap-4 items-center" },
                                isHighestBid ? (react_1.default.createElement("div", { className: "bg-blue-500 w-28 py-1 rounded-full flex flex-row justify-center items-center" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Highest Bid", textColor: "text-white" }))) : (react_1.default.createElement("div", { className: "w-28" })),
                                react_1.default.createElement("div", { className: "flex flex-col flex-none" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, functions_1.formatMoney)((_b = bid.amount) !== null && _b !== void 0 ? _b : 0), textColor: "text-black", size: "medium" }),
                                    react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center", onClick: function () {
                                            if (bid.bidder && bid.user) {
                                                dispatch((0, bidder_1.setSelectedBidder)(__assign(__assign({}, bid.bidder), { user: __assign({}, bid.user) })));
                                                navigate("/console/bidders/details");
                                            }
                                        } },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "".concat(bid.bidder ? "hover:underline cursor-pointer" : ""), label: "".concat(bid.user.name, " ").concat(bid.user.surname), textColor: "text-black", size: "xsmall" }),
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "(#".concat((_c = bid.bidder) === null || _c === void 0 ? void 0 : _c.paddle_number, ")"), textColor: "text-black", size: "xsmall", bold: true }))),
                                react_1.default.createElement("div", { className: "flex-1 flex justify-end flex-nowrap" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, functions_1.formatDate)(bid.created_at), textColor: "text-black", size: "xsmall" })),
                                react_1.default.createElement("div", { className: "flex justify-end" },
                                    react_1.default.createElement("div", { className: "px-4 py-2 text-white rounded-md ".concat(bid.status == "Rejected" ? "bg-red-500" : "bg-green-500") },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bid.status, textColor: "text-white", size: "xsmall" })))));
                        })
                        : "No bids for this lot."))));
            })) : (react_1.default.createElement("div", null, "No lots to view bids for. If there are no lots, there are also, most likely, no bids yet.")))))));
};
exports.ArchivedPage = ArchivedPage;
