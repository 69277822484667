"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionsScreen = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_all_auctions_1 = require("../../../api/calls/auction/v3/get_all_auctions");
// import { TableControl } from "../../../controls/table/table";
var auction_1 = require("../../../reducers/slices/auction");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var auction_2 = require("./auction");
var delete_auction_1 = require("../../../api/calls/auction/v3/delete_auction");
var functions_1 = require("../../../constants/functions");
var auctions_for_client_1 = require("../../../api/calls/archive/auctions_for_client");
var restore_auction_1 = require("../../../api/calls/archive/restore_auction");
var archive_auction_1 = require("../../../api/calls/archive/archive_auction");
var delete_archived_auction_1 = require("../../../api/calls/archive/delete_archived_auction");
var archived_1 = require("./archived");
var messages_1 = require("../../../constants/messages");
var AuctionsScreen = function (props) {
    var _a, _b;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.auctionState; }), auctions = _c.auctions, selectedAuction = _c.selectedAuction;
    var selectedClient = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClient;
    var _d = (0, react_1.useState)([]), archivedAuctions = _d[0], setArchivedAuctions = _d[1];
    (0, react_1.useEffect)(function () {
        initialize();
    }, []);
    var initialize = function (wait) {
        if (wait === void 0) { wait = 250; }
        setTimeout(function () {
            if (selectedClient) {
                (0, get_all_auctions_1.get_all_auctions)(selectedClient.entity_id).then(function (response) {
                    if (response.data.success) {
                        dispatch((0, auction_1.setAuctions)(response.data.content));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting auctions!", messages: [response.data.message] }));
                    }
                });
                (0, auctions_for_client_1.archived_auctions_for_client)({
                    entity_id: selectedClient.entity_id,
                }).then(function (response) {
                    if (response.data.success) {
                        console.log("archived_auctions_for_client", response.data.content);
                        setArchivedAuctions(response.data.content);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting archived auctions!", messages: [response.data.message] }));
                    }
                });
            }
        }, wait);
    };
    (0, react_1.useEffect)(function () {
        if (selectedClient) {
            initialize();
        }
    }, [selectedClient]);
    return (react_1.default.createElement("div", { className: "flex flex-col w-full" },
        react_1.default.createElement("div", { className: "flex flex-col space-y-4 px-4 pt-4 w-full" },
            react_1.default.createElement(react_router_dom_1.Routes, null,
                react_1.default.createElement(react_router_dom_1.Route, { path: "/", element: react_1.default.createElement("div", { className: "flex flex-col space-y-4" },
                        react_1.default.createElement("div", { className: "text-2xl font-bold" },
                            "Auctions for ", (_a = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.name) !== null && _a !== void 0 ? _a : "[select a client]"),
                        react_1.default.createElement("div", { className: "flex justify-between" },
                            react_1.default.createElement("div", { className: "flex gap-4" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create Auction", backgroundColor: "bg-green-500", size: "small", onClick: function () {
                                        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.entity_id) {
                                            dispatch((0, auction_1.setIsEditing)(false));
                                            dispatch((0, auction_1.setSelectedAuction)(null));
                                            navigate("".concat(pathname, "/auction"));
                                        }
                                        else {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Select a client",
                                                messages: ["Please select a client (top-left) before trying to create an auction."],
                                                // buttons: "yesNo",
                                                onConfirm: function () {
                                                    //
                                                },
                                            }));
                                        }
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Refresh List", backgroundColor: "bg-blue-500", size: "small", onClick: function () {
                                        initialize();
                                    } })),
                            react_1.default.createElement("div", { className: "w-40" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                                    { label: "Title", displayKey: "title" },
                                    { label: "Type", displayKey: "type" },
                                    { label: "Status", displayKey: "status" },
                                    {
                                        label: "Automated",
                                        displayKey: "",
                                        formatter: function (o) {
                                            var _a;
                                            return ((_a = o.automated) === null || _a === void 0 ? void 0 : _a.enabled) ? "Yes" : "No";
                                        },
                                    },
                                    {
                                        label: "Date From",
                                        displayKey: "date_from",
                                        formatter: function (o) {
                                            return (0, functions_1.formatDate)(o.date_from);
                                        },
                                    },
                                ], 
                                // pagination={{
                                //   rowsPerPage: undefined,
                                // }}
                                data: auctions, 
                                // actionButton={{ title: "Action", side: "right", items: [{}] }}
                                rowActions: [
                                    {
                                        label: "Archive",
                                        onClick: function (row) {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Archive?",
                                                messages: messages_1.message_archive_auction,
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, archive_auction_1.archive_auction)({
                                                        entity_id: row.entity_id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                            initialize(0);
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while archiving the auction!", messages: [response.data.message] }));
                                                        }
                                                    });
                                                },
                                            }));
                                        },
                                    },
                                    {
                                        label: "Delete",
                                        onClick: function (row) {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Are you sure?",
                                                messages: ["Are you sure that you want to delete this auction?"],
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, delete_auction_1.delete_auction)({
                                                        entity_id: row.entity_id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                            initialize(0);
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while deleting the auction!", messages: [response.data.message] }));
                                                        }
                                                    });
                                                },
                                            }));
                                        },
                                    },
                                ], onRowClicked: function (row) {
                                    console.log("row", row);
                                    dispatch((0, auction_1.setIsEditing)(true));
                                    // dispatch(setSelectedAuction(row));
                                    navigate("".concat(pathname, "/auction/").concat(row.entity_id));
                                }, colors: {
                                    headerBackgroundColor: "bg-bluish",
                                    headerTextColor: "text-white",
                                } })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement("div", { className: "text-2xl font-bold pb-4" },
                                "Archived Auctions for ", (_b = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.name) !== null && _b !== void 0 ? _b : "[select a client]"),
                            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                                    { label: "Title", displayKey: "title" },
                                    { label: "Type", displayKey: "type" },
                                    { label: "Status", displayKey: "status" },
                                    {
                                        label: "Automated",
                                        displayKey: "",
                                        formatter: function (o) {
                                            var _a;
                                            return ((_a = o.automated) === null || _a === void 0 ? void 0 : _a.enabled) ? "Yes" : "No";
                                        },
                                    },
                                    {
                                        label: "Archived At",
                                        displayKey: "archived_at",
                                        formatter: function (o) {
                                            return (0, functions_1.formatDate)(o.archived_at);
                                        },
                                    },
                                ], 
                                // pagination={{
                                //   rowsPerPage: undefined,
                                // }}
                                data: archivedAuctions, 
                                // actionButton={{ title: "Action", side: "right", items: [{}] }}
                                rowActions: [
                                    {
                                        label: "Restore",
                                        onClick: function (row) {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Restore?",
                                                messages: messages_1.message_restore_auction,
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, restore_auction_1.restore_auction)({
                                                        _id: row._id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                            initialize(0);
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while restoring the auction!", messages: [response.data.message] }));
                                                        }
                                                    });
                                                },
                                            }));
                                        },
                                    },
                                    {
                                        label: "Delete",
                                        onClick: function (row) {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Are you sure?",
                                                messages: ["Are you sure that you want to delete this archived auction? This cannot be undone!"],
                                                buttons: "yesNo",
                                                onConfirm: function () {
                                                    (0, delete_archived_auction_1.delete_archived_auction)({
                                                        _id: row._id,
                                                    }).then(function (response) {
                                                        if (response.data.success) {
                                                            dispatch((0, main_1.setMessageModal)(null));
                                                            initialize(0);
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Something went wrong while deleting the archived auction!",
                                                                messages: [response.data.message],
                                                            }));
                                                        }
                                                    });
                                                },
                                            }));
                                        },
                                    },
                                ], onRowClicked: function (row) {
                                    navigate("".concat(pathname, "/archive/").concat(row._id));
                                }, colors: {
                                    headerBackgroundColor: "bg-bluish",
                                    headerTextColor: "text-white",
                                } }))) }),
                react_1.default.createElement(react_router_dom_1.Route, { path: "/auction/:auction_entity_id?", element: react_1.default.createElement(auction_2.AuctionScreen, { onSuccess: function () {
                            initialize(0);
                        } }) }),
                react_1.default.createElement(react_router_dom_1.Route, { path: "/archive/:_id?", element: react_1.default.createElement(archived_1.ArchivedPage, { onSuccess: function () {
                            initialize(0);
                        } }) })))));
};
exports.AuctionsScreen = AuctionsScreen;
